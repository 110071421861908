<template>
  <div>
    <v-form ref="form2" v-model="valid" lazy-validation>
      <v-dialog v-model="showForm" max-width="1000px" persistent>
        <v-card>
          <v-card-title>
            <span class="headline">{{ formTitle }} </span>
            <v-spacer></v-spacer>
            <h2 v-if="editedItem.sens">
              {{ editedItem.sens == "1" ? "اقتراض" : "اقراض" }}
            </h2>

            <v-spacer></v-spacer>
            <v-chip :color="editedItem.status_color">
              {{ editedItem.status_name }}
            </v-chip>
          </v-card-title>
          <v-card-subtitle>
            {{ editedItem.tier_name }}
          </v-card-subtitle>
          <v-card-subtitle v-if="editedItem.id > 0 && editedItem.statut_id > 1">
            <v-row>
              <v-col cols="12" sm="3" md="3">
                {{
                  "Montant Credit : " +
                  (editedItem.montant
                    ? numberWithSpace(parseFloat(editedItem.montant).toFixed(2))
                    : 0.0)
                }}
              </v-col>
              <v-col cols="12" sm="3" md="3">
                {{
                  "Montant Remboursé : " +
                  numberWithSpace(
                    editedItem.ttc_remb
                      ? parseFloat(editedItem.ttc_remb).toFixed(2)
                      : 0.0
                  )
                }}
              </v-col>
              <v-col cols="12" sm="3" md="3">
                {{
                  "Montant Restant : " +
                  numberWithSpace(
                    editedItem.reste_remb
                      ? parseFloat(editedItem.reste_remb).toFixed(2)
                      : 0.0
                  )
                }}
              </v-col>
            </v-row>
          </v-card-subtitle>
          <v-card-text>
            <v-container>
              <v-tabs class="my-tabs" v-model="tab1" @change="tab_change">
                <v-tab href="#4" key="4">
                  <v-icon left> mdi-account-cash-outline </v-icon>
                  Credit
                </v-tab>
                <v-spacer></v-spacer>
                <v-tab href="#8" key="8" v-if="editedItem.id != -1">
                  Documents attachés
                  <v-badge
                    bordered
                    :color="editedItem.nbdocs == 0 ? 'red' : 'blue'"
                    overlap
                  >
                    <template v-slot:badge>
                      <span> {{ editedItem.nbdocs }} </span>
                    </template>
                    <v-icon left> mdi-paperclip </v-icon>
                  </v-badge>
                </v-tab>
                <v-tabs-items v-model="currentTab">
                  <v-tab-item :value="'4'">
                    <v-container>
                      <v-row>
                        <v-col
                          cols="12"
                          sm="6"
                          md="6"
                          v-if="!tier && editedItem.id == -1"
                        >
                          <v-autocomplete
                            v-model="editedItem.tier_id"
                            :items="tiers"
                            :item-text="'nom'"
                            :item-value="'id'"
                            label="Tier"
                            :rules="[(v) => !!v || 'Tier obligatoire']"
                            outlined
                            dense
                            @change="tier_change"
                            :readonly="!modify"
                          >
                            <template v-slot:item="{ item }">
                              <v-list-item-content>
                                <v-list-item-title>
                                  {{ item.nom }}
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                  {{
                                    item.tier_type_id == 1
                                      ? "Client"
                                      : item.tier_type_id == 2
                                      ? "Fournisseur"
                                      : "Autre"
                                  }}
                                </v-list-item-subtitle>

                                <v-list-item-subtitle>
                                  {{
                                    "Solde " +
                                    (item.solde
                                      ? numberWithSpace(
                                          parseFloat(item.solde).toFixed(2)
                                        )
                                      : 0.0)
                                  }}
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </template>
                          </v-autocomplete>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="3" md="3">
                          <v-select
                            v-model.number="editedItem.sens"
                            :items="[
                              { id: 1, label: 'Emprunt de (اقتراض)' },
                              { id: -1, label: 'Emprunt à (اقراض)' },
                            ]"
                            item-text="label"
                            item-value="id"
                            label="Operation"
                            :rules="[(v) => !!v || 'Operation obligatoire']"
                            dense
                            outlined
                            :readonly="!modify"
                          >
                            <template v-slot:selection="{}">
                              <v-chip
                                :color="
                                  editedItem.sens == 1 ? 'green' : 'orange'
                                "
                                small
                              >
                                <span>{{
                                  editedItem.sens == 1
                                    ? "Emprunt de (اقتراض)"
                                    : "Emprunt à (اقراض)"
                                }}</span>
                              </v-chip>
                            </template>
                            <template v-slot:item="{ item }">
                              <v-chip
                                :color="item.id == 1 ? 'green' : 'orange'"
                                small
                              >
                                <span>{{
                                  item.id == 1
                                    ? "Emprunt de (اقتراض)"
                                    : "Emprunt à (اقراض)"
                                }}</span>
                              </v-chip>
                            </template>
                          </v-select>
                        </v-col>

                        <v-col cols="12" sm="3" md="3">
                          <currencyinput
                            label="Montant"
                            v-model="editedItem.montant"
                            :rules="[
                              (v) => !!v || v > 0 || 'Montant obligatoire',
                            ]"
                            :readonly="!modify"
                            :key="mk"
                            @input="mont_change"
                            @focus="$event.target.select()"
                          />
                        </v-col>
                        <v-col cols="12" sm="3" md="3">
                          <v-autocomplete
                            v-model="editedItem.currency_id"
                            :items="currencies"
                            :item-text="'name'"
                            :item-value="'id'"
                            label="Monnaie"
                            :filter="customFilter"
                            :rules="[(v) => !!v || 'Monnaie obligatoire']"
                            outlined
                            dense
                            :readonly="
                              !modify ||
                              editedItem.trans_id > 0 ||
                              editedItem.credit_id > 0 ||
                              editedItem.echeance_id > 0
                            "
                          >
                            <template v-slot:item="{ item }">
                              <v-list-item-content>
                                <v-list-item-title
                                  v-html="item.name"
                                ></v-list-item-title>

                                <v-list-item-subtitle
                                  v-html="
                                    item.currency_unit_label +
                                    ' (' +
                                    item.symbol +
                                    ')'
                                  "
                                ></v-list-item-subtitle>
                              </v-list-item-content>
                            </template>
                          </v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="3" md="3">
                          <datepicker
                            :label="'Date Credit'"
                            v-model="editedItem.credit_date"
                            :value="editedItem.credit_date"
                            :edit="modify"
                            :clearable="false"
                            :date_max="$store.state.today"
                            :outlined="true"
                            :rules="[
                              (v) =>
                                !!v ||
                                (dz == 1 && !tovalid) ||
                                'Date obligatoire',
                            ]"
                            :key="cs"
                          ></datepicker>
                        </v-col>
                        <v-col
                          cols="12"
                          sm="4"
                          md="4"
                          v-if="
                            editedItem.currency_id &&
                            editedItem.currency_id != 114
                          "
                        >
                          <v-text-field
                            autocomplete="off"
                            type="number"
                            outlined
                            dense
                            v-model.number="editedItem.taux_change"
                            label="Taux de change"
                            :rules="[
                              (v) =>
                                !!v || v > 0 || 'Taux de change obligatoire',
                            ]"
                            :readonly="
                              !modify ||
                              editedItem.trans_id > 0 ||
                              editedItem.credit_id > 0 ||
                              editedItem.echeance_id > 0
                            "
                            @focus="$event.target.select()"
                            @input="mont_change"
                            hide-spin-buttons
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="12"
                          sm="4"
                          md="4"
                          v-if="
                            editedItem.currency_id &&
                            editedItem.currency_id != 114
                          "
                        >
                          <currencyinput
                            label="Montant DA"
                            v-model="editedItem.mont_dz"
                            readonly
                            :key="mk2"
                            @focus="$event.target.select()"
                            dense
                          />
                        </v-col>
                      </v-row>

                      <v-row no-gutters>
                        <v-col cols="12" sm="8" md="8" v-if="editedItem.id > 0">
                          <v-card>
                            <v-card-title>Echéance</v-card-title>
                            <v-card-text>
                              <v-container fluid>
                                <listitems
                                  :list="echeances"
                                  title="Echéance"
                                  :qDelete="Qdelete_echeance"
                                  :showedit="false"
                                  :showstd="false"
                                  :headers="ech_headers"
                                  @rowselect="EchSelect"
                                  @open="EchOpen"
                                  :master="false"
                                  :add="
                                    editedItem.statut_id == 1 &&
                                    sum_echeances <
                                      parseFloat(editedItem.montant)
                                  "
                                  :del="editedItem.statut_id == 1"
                                  :Update="editedItem.statut_id == 1"
                                  :key="kech"
                                  :Total="true"
                                >
                                </listitems>
                              </v-container>
                            </v-card-text>
                          </v-card>
                        </v-col>
                        <v-col cols="12" sm="4" md="4">
                          <v-card>
                            <v-card-title>Exécution</v-card-title>
                            <v-card-text>
                              <v-container fluid>
                                <v-row>
                                  <v-col
                                    cols="12"
                                    sm="12"
                                    md="12"
                                    v-if="
                                      $store.state.auth.includes('02003') ||
                                      $store.state.isadmin
                                    "
                                  >
                                    <v-select
                                      v-model="editedItem.user_id"
                                      :items="users_list"
                                      item-text="name"
                                      item-value="id"
                                      label="Agent"
                                      :rules="[
                                        (v) => !!v || 'Agent obligatoire',
                                      ]"
                                      dense
                                      outlined
                                      :readonly="!modify"
                                    >
                                      <template v-slot:item="{ item }">
                                        <v-list-item-content>
                                          <v-list-item-title>
                                            {{ item.name }}
                                          </v-list-item-title>

                                          <v-list-item-subtitle>
                                            {{
                                              "Solde " +
                                              (item.solde
                                                ? numberWithSpace(
                                                    parseFloat(
                                                      item.solde
                                                    ).toFixed(2)
                                                  )
                                                : 0.0)
                                            }}
                                          </v-list-item-subtitle>
                                        </v-list-item-content>
                                      </template>
                                    </v-select>
                                  </v-col>
                                  <v-col
                                    cols="12"
                                    sm="12"
                                    md="12"
                                    v-if="
                                      $store.state.auth.includes('02003') ||
                                      $store.state.isadmin
                                    "
                                  >
                                    <datepicker
                                      label="Date Max"
                                      v-model="editedItem.date_limite"
                                      :value="editedItem.date_limite"
                                      :edit="modify"
                                      :date_min="$store.state.today"
                                      :clearable="false"
                                      :outlined="true"
                                      :key="cs"
                                      dense
                                    ></datepicker>
                                  </v-col>
                                </v-row>
                              </v-container>
                            </v-card-text>
                          </v-card>
                        </v-col>
                      </v-row>

                      <v-row no-gutters>
                        <v-col cols="12" sm="12" md="12">
                          <v-text-field
                            outlined
                            type="text"
                            label="Comment"
                            v-model="editedItem.comment"
                            :readonly="!modify"
                            dense
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-tab-item>
                  <v-tab-item :value="'8'">
                    <filelist
                      :item="editedItem"
                      :isform="false"
                      :key="fdocl"
                      :editer="modify"
                      :doc_type="1"
                      :auth="auth"
                      @file_added="file_added"
                      @file_deleted="file_deleted"
                    >
                    </filelist>
                  </v-tab-item>
                </v-tabs-items>
              </v-tabs>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-checkbox
              v-model="editedItem.email_agent"
              label="E-mail Agent"
              v-if="
                editedItem.statut_id == 1 &&
                editedItem.id > 0 &&
                ($store.state.auth.includes('02005') || $store.state.isadmin)
              "
            ></v-checkbox>
            <v-checkbox
              v-model="editedItem.email_opr"
              label="E-mail Operateur"
              v-if="
                editedItem.statut_id == 1 &&
                editedItem.id > 0 &&
                ($store.state.auth.includes('02005') || $store.state.isadmin)
              "
            ></v-checkbox>
            <v-spacer></v-spacer>

            <v-btn
              color="blue darken-1"
              @click="validate"
              v-if="
                editedItem.statut_id == 1 &&
                editedItem.id > 0 &&
                ($store.state.auth.includes('04005') || $store.state.isadmin)
              "
              :disabled="validating"
              :loading="validating"
            >
              Valider
            </v-btn>
            <v-btn
              color="blue darken-1"
              @click="save"
              v-if="
                editedItem.statut_id == 1 &&
                ($store.state.auth.includes('02005') || $store.state.isadmin)
              "
              :disabled="ch == 2 || saving"
              :loading="saving"
            >
              Enregistrer
            </v-btn>
            <v-btn color="blue darken-1" @click="close"> Fermer </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>
    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
    <template>
      <confirmdialog ref="confirm" />
    </template>
    <echeanceform
      :item="echeance"
      :list="echeances"
      :showForm="!isEcheanceClosed"
      :credit="editedItem"
      :modify="modify"
      @close="EchClose"
      :key="kef"
    ></echeanceform>
  </div>
</template>

<script>
import CREATE_CREDIT from "../graphql/Credits/CREATE_CREDIT.gql";
import UPDATE_CREDIT from "../graphql/Credits/UPDATE_CREDIT.gql";

import DELETE_ECHEANCE from "../graphql/Credits/DELETE_ECHEANCE.gql";

export default {
  name: "creditform",
  components: {
    datepicker: () => import("./DatePicker.vue"),
    currencyinput: () => import("./CurrencyInput.vue"),
    echeanceform: () => import("./EcheanceForm.vue"),
    filelist: () => import("../components/FileList.vue"),
    confirmdialog: () => import("../components/ConfirmDialog.vue"),
    listitems: () => import("../components/ListItems.vue"),
  },
  props: {
    item: Object,
    showForm: Boolean,
    tier: Object,
    list: Array,
    currencies: Array,
    modes: Array,
    tiers: Array,
    users: Array,
    annuler: Boolean,
  },
  watch: {
    editedItem: {
      handler() {
        this.ch++;
      },
      deep: true,
    },
  },
  data: () => ({
    tab1: 1,
    currentTab: 1,
    valid: true,
    villes: [],
    editedItem: {},
    kef: 250,
    defaultItem: {
      id: -1,
      statut_id: 1,
      taux_change: 1,
      status_name: "Brouillon",
      status_color: "gray",
      email_agent: 1,
      email_opr: 1,
      nbdocs: 0,
    },

    ech_headers: [
      {
        text: "N°",
        value: "no",
        selected: true,
        index: 0,
      },

      {
        text: "Date",
        value: "date_ech",
        selected: true,
        slot: "date",
        expire: true,
        index: 2,
      },
      {
        text: "Montant",
        value: "montant",
        total: "montant",
        selected: true,
        slot: "href22",
        align: "end",
        index: 3,
      },

      {
        text: "Status",
        value: "status_name",
        slot: "chip",
        color: "status_color",
        selected: true,
        index: 8,
      },
    ],
    saving: false,
    validating: false,
    isEcheanceClosed: true,
    cs: 120,
    ch: 0,
    mk: 100,
    kech: 200,
    fdocl: 1000,
    dz: 2,
    modify: false,
    echeance: {},
    tovalid: false,
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: "",
    snackbar_color: "primary",
    phoneNumber: "+213550910239", // The phone number in international format
    message: "Hello, I want to know more about your services.",
  }),

  computed: {
    auth() {
      let a = this.istier == 0 ? "01001" : this.istier == 1 ? "01029" : "01030";
      return a;
    },
    Qdelete_echeance() {
      return DELETE_ECHEANCE;
    },
    formTitle() {
      return this.editedItem.id == -1
        ? "Nouveau Credit"
        : "Credit N° " + this.editedItem.no_credit;
    },
    echeances() {
      let l = [];
      if (this.editedItem.echeances) l = this.editedItem.echeances;
      return l;
    },
    sum_echeances() {
      return this.echeances.reduce(
        (a, b) => a + (parseFloat(b["montant"]) || 0),
        0
      );
    },
    users_list() {
      let l = [];
      if (this.users)
        this.users.forEach((element) => {
          if (element.autorisations) {
            if (element.autorisations.split(",").includes("03003"))
              l.push(element);
          } else if (element.profile.autorisations) {
            if (element.profile.autorisations.split(",").includes("03003"))
              l.push(element);
          }
        });

      return l;
    },
  },

  created() {},
  mounted() {
    if (this.item) {
      if (this.item.id > 0) {
        this.editedItem = Object.assign({}, this.item);
        this.mk++;
      } else {
        this.editedItem = Object.assign({}, this.defaultItem);

        if (this.tier) {
          this.editedItem.tier_id = this.tier.id;
          this.editedItem.tier_name = this.tier.nom;
          this.editedItem.tier_type_id = this.tier.tier_type_id;
        }

        if (this.users_list.length == 1)
          this.editedItem.user_id = this.users_list[0].id;
      }
      this.modify =
        (this.editedItem.statut_id == 1 || this.editedItem.id < 0) &&
        (this.$store.state.auth.includes("02005") || this.$store.state.isadmin);
      this.cs++;
    }
  },
  methods: {
    customFilter(item, queryText) {
      let textlist = [];
      ["name", "symbol", "currency_unit_label"].forEach((element) => {
        const text1 = item[element] ? item[element].toLowerCase() : "";
        textlist.push(text1);
      });

      const searchText = queryText.toLowerCase();
      const index = textlist.findIndex((element) => {
        if (element.includes(searchText)) {
          return true;
        }
      });
      return index !== -1;
    },
    mont_change() {
      if (this.editedItem.montant && this.editedItem.taux_change) {
        this.editedItem.mont_dz = parseFloat(
          this.editedItem.montant * this.editedItem.taux_change
        ).toFixed(2);
        this.mk2++;
      }
    },
    numberWithSpace: function (x) {
      return x != null
        ? x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, " ")
        : "0";
    },
    tier_change() {
      let i = this.tiers.findIndex((elm) => elm.id == this.editedItem.tier_id);
      if (i >= 0) {
        this.editedItem.tier_name = this.tiers[i].nom;
        this.editedItem.tier_type_id = this.tiers[i].tier_type_id;
      }
    },
    tab_change() {
      this.currentTab = this.tab1;
    },
    EchSelect(item) {
      this.echeance = item;
    },
    EchOpen(item) {
      this.echeance = item;
      this.isEcheanceClosed = false;
      this.kef++;
    },
    EchClose() {
      this.isEcheanceClosed = true;
    },
    close() {
      this.$emit("close");
    },
    async maj(query, v) {
      let r;
      this.progress = true;
      await this.$apollo
        .mutate({
          mutation: query,
          variables: v,
        })
        .then((data) => {
          r = data.data;
          this.progress = false;
        })
        .catch((error) => {
          this.progress = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },
    file_added(n) {
      this.editedItem.nbdocs = parseInt(this.editedItem.nbdocs) + n;
    },
    file_deleted(n) {
      this.editedItem.nbdocs = parseInt(this.editedItem.nbdocs) - n;
    },

    async validate() {
      this.tovalid = true;
      if (this.$refs.form2.validate()) {
        let ok = true;
        let w = "";
        if (this.sum_echeances != parseFloat(this.editedItem.montant)) {
          ok = false;
          this.snackbar_text =
            "Total des echéances different du montant du credit - erreur";
          this.snackbar_color = "error";
          this.snackbar = true;
        }
        if (ok && this.editedItem.sens == -1) {
          let i = this.users_list.findIndex(
            (elm) => elm.id == this.editedItem.user_id
          );
          if (i >= 0) {
            if (this.users_list[i].solde < this.editedItem.montant) {
              w =
                "Solde de votre agent : " +
                this.numberWithSpace(this.users_list[i].solde) +
                " insuffisant pour ce credit";
            }
          }
        }
        if (ok)
          if (
            await this.$refs.confirm.open(
              "Confirmation",
              "Valider ce credit?",
              { color: "orange darken-3" },
              w
            )
          ) {
            this.validating = true;

            let v = {
              credit: {
                id: this.editedItem.id,
                statut_id: 2,
                email_agent: this.editedItem.email_agent ? 1 : 0,
                email_opr: this.editedItem.email_opr ? 1 : 0,
                sens: this.editedItem.sens,
                credit_date: this.editedItem.credit_date,
                date_limite: this.editedItem.date_limite,
                ref: this.editedItem.ref,
                currency_id: this.editedItem.currency_id,
                taux_change: this.editedItem.taux_change,
                comment: this.editedItem.comment,
                montant: parseFloat(this.editedItem.montant),
                write_uid: this.$store.state.me.id,
              },
            };
            let r = await this.maj(UPDATE_CREDIT, v);
            if (r) {
              this.editedItem.operation = this.editedItem.sens == 1 ? "C" : "D";

              this.editedItem.color =
                this.editedItem.sens == 1 ? "green" : "orange";

              this.editedItem.statut_id = 2;
              this.editedItem.status_name = "Valid";
              this.editedItem.status_color = "green";
              if (this.list) {
                this.$emit("refresh");
              } else this.$store.dispatch("Changed", true);
              this.$store.dispatch("alert", true);

              this.close();
            }
            this.validating = false;
          }
      }
    },
    async save() {
      if (this.$refs.form2.validate()) {
        this.saving = true;
        this.editedItem.operation =
          this.editedItem.sens == 1 ? "Pret de " : "Emprunt A";
        this.editedItem.color = this.editedItem.sens == 1 ? "green" : "orange";

        this.editedItem.status_name = "Att. Valid";

        let i = this.users.findIndex(
          (elm) => elm.id == this.editedItem.user_id
        );
        if (i >= 0) this.editedItem.user_name = this.users[i].name;
        i = this.currencies.findIndex(
          (elm) => elm.id == this.editedItem.currency_id
        );
        if (i >= 0) this.editedItem.currency = this.currencies[i].name;
        this.editedItem.statut_id = 1;
        if (this.editedItem.id > -1) {
          let v = {
            credit: {
              id: this.editedItem.id,
              user_id: this.editedItem.user_id,
              email_agent: this.editedItem.email_agent ? 1 : 0,
              email_opr: this.editedItem.email_opr ? 1 : 0,
              sens: this.editedItem.sens,
              credit_date: this.editedItem.credit_date,
              date_limite: this.editedItem.date_limite,
              ref: this.editedItem.ref,
              comment: this.editedItem.comment,
              currency_id: this.editedItem.currency_id,
              taux_change: this.editedItem.taux_change,
              montant: parseFloat(this.editedItem.montant),
              write_uid: this.$store.state.me.id,
            },
          };
          let r = await this.maj(UPDATE_CREDIT, v);
          if (r) {
            if (this.list) this.$emit("refresh");
            else this.$store.dispatch("Changed", true);
            this.ch = 2;
          }
          this.saving = false;
        } else {
          let v = {
            credit: {
              tier_id: this.editedItem.tier_id,
              user_id: this.editedItem.user_id,
              email_agent: this.editedItem.email_agent ? 1 : 0,
              email_opr: this.editedItem.email_opr ? 1 : 0,
              statut_id: this.editedItem.statut_id,
              sens: this.editedItem.sens,
              credit_date: this.editedItem.credit_date,
              date_limite: this.editedItem.date_limite,
              ref: this.editedItem.ref,
              comment: this.editedItem.comment,
              currency_id: this.editedItem.currency_id,
              taux_change: this.editedItem.taux_change,
              montant: parseFloat(this.editedItem.montant),
              create_uid: this.$store.state.me.id,
            },
          };
          let r = await this.maj(CREATE_CREDIT, v);
          if (r) {
            this.editedItem.id = r.createCredit.id;
            this.editedItem.no_credit = r.createCredit.no_credit;
            if (this.list) {
              this.list.unshift(this.editedItem);
              this.$store.dispatch("alert", true);
              this.$emit("refresh");
            } else this.$store.dispatch("Changed", true);
            this.ch = 2;
            this.kech++;
          }
          this.saving = false;
        }
      }
    },
  },
};
</script>
